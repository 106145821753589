<template>
<b-row>
  <b-col class="duck-conte-list-document" cols="12" md="12">
    <div v-for="(document, index) in $props.json_config.documents" class="duck-list-document d-flex align-content-center align-items-center" :style="'background-color: '+document.background+'; color:'+document.textColor+'; font-size: '+document.fontSize">
      <div class="rounded-circle align-items-center align-content-center" :style="'border-color: '+document.textColor">
        {{(index+1)}}
      </div>
      <div class="doctitle d-flex align-content-center align-items-center">
        <a :href="document.url" target="_blank" :style="'color:'+document.textColor+'; font-size: '+document.fontSize">
          {{document.title}}
        </a>
        <a class="ml-auto" :href="document.url" target="_blank" :style="'color:'+document.textColor+'; font-size: '+document.fontSize">
          <b-icon icon="file-arrow-down-fill" ></b-icon>
        </a>
      </div>
    </div>
  </b-col>
</b-row>
</template>

<script>
export default {
  name: "ListDocuments",
  props:{
    json_config: {
      type: Object
    }
  }
}
</script>

<style scoped>
.duck-conte-list-document{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.duck-list-document{
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.5rem;
}
.doctitle{
  margin-left: 10%;
  width: 90%;
}
.rounded-circle{
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid #222222;
  font-size: 1.5rem;
}
</style>
