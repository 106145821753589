<template>
<b-row>
  <b-col class="text-center align-content-center title" v-html="$props.title" :style="cssVariables" >
  </b-col>
</b-row>
</template>

<script>
export default {
  name: "Title",
  props:{
    json_config: {
      type: Object
    },
    title: {
      type: String
    },
  },
  computed: {
    cssVariables() {
      if(!this.json_config) return {}
      // Devuelve un objeto con variables CSS dinámicas
      return {
        "--font-size": this.json_config.fontSize || "2rem",
        "--text-color": this.json_config.color || "#222222",
        "--font-weight": this.json_config.fontWeight || "bold",
        "--min-height": this.json_config.minHeight || "100px",
        "--background-color": this.json_config.backgroundColor || "transparent"
      };
    },
  }
}
</script>

<style scoped>
.title {
  font-size: var(--font-size, 2rem);
  color: var(--text-color, #222222);
  font-weight: var(--font-weight, bold);
  min-height: var(--min-height, 100px);
  background-color: var(--background-color, transparent);
}
</style>
