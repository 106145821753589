<template>
  <section class="banner">
    <b-container id="banner_image" fluid v-if="$props.video === undefined || $props.video===''"
                 class="banner_container" :style="'background-image:url('+$props.background+');background-size:cover;text-align:'+$props.contentAlign">
      <b-container class="pt-5 pb-5" style="max-width: 1280px;">
        <h1 class="title" :style="'width:'+$props.widthTitle+';'"> {{$props.title}}</h1>
        <h4 class="subtitle mb-5" :style="'width:'+$props.widthSubtitle+';'"> {{$props.subtitle}}</h4>
        <div>
          <ButtonComponent v-if="button.type == 'simple'" class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonComponent>
          <ButtonMeetingComponent v-if="button.type == 'meeting'" class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonMeetingComponent>
        </div>
      </b-container>
    </b-container>
    <div  id="videoDiv" v-if="$props.video != undefined && $props.video!=''" :style="'text-align:'+$props.contentAlign">
      <video  id="video1" preload="" autoplay="" muted="" playsinline="" loop="">
        <source :src="$props.video" type="video/mp4">
      </video>
      <div id="videoMessage" :style="'text-align:'+$props.contentAlign">
          <h1 class="title pt-4" :style="'text-align:'+$props.contentAlign"> {{$props.title}}</h1>
          <h4 class="subtitle pb-4" :style="'text-align:'+$props.contentAlign"> {{$props.subtitle}}</h4>
          <!--<div class="container-fluid">
            <ButtonComponent class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonComponent>
          </div>-->
          <div :style="'text-align:'+$props.contentAlign">
            <ButtonComponent v-if="button.type == 'simple'" class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonComponent>
            <ButtonMeetingComponent v-if="button.type == 'meeting'" class="mr-2" v-for="button in $props.buttons" :key="button.id" :text="button.text" :variant="button.variant" :href="button.link" :icon="button.icon"></ButtonMeetingComponent>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonComponent from '../buttons/ButtonComponent.vue';
import ButtonMeetingComponent from "@/packages/ducksites.ui/components/buttons/ButtonMeetingComponent.vue";
export default {
  name: 'BannerProductComponent',
  components: { ButtonMeetingComponent, ButtonComponent },
  props: {
    title: String,
    subtitle: String,
    image: String,
    background: String,
    buttons: Array,
    video: String,
    contentAlign: {
      type: String,
      default: 'center'
    },
    contentDirection: {
      type: String,
      default: 'center'
    },
    widthTitle: {
      type: String,
      default: '70%'
    },
    widthSubtitle:{
      type: String,
      default: '60%'
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {

  }
  .banner_container{
    padding:2rem;
  }
  #banner_image {
    position: relative; top: 0; left: 0;
    z-index:2;
  }
  #banner_image:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:-1;
    background:linear-gradient(to right,rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5));
  }
  .title{
    margin-top:0.5em;
    /*font-size:3.5em;*/
    font-size: calc(2.2rem + 1vw);
    line-height: calc(2.3rem + 1vw);
    font-weight: 500;
    color:#ffffff;
    width: 70%;
    text-shadow: 2px 2px #222222;
    z-index:2;
    @media (max-width: 768px) {
      font-size:2.0em;
    }
  }
  .subtitle{
    /*font-size:2em;*/
    font-size: calc(1.2rem + 1vw);
    line-height: calc(1.2rem + 1vw);
    color: #fefefe;
    text-shadow: 1px 1px #222222;
    width: 50%;
    font-weight:400;
    z-index:2;
  }
  @media (max-width: 1024px) {
    .title {
      font-size: calc(2.6rem + 1vw);
      line-height: calc(3rem + 1vw);
    }
    .subtitle {
      font-size: calc(1rem + 1vw);
      line-height: calc(1.2rem + 1vw);
      width: 100%;
    }
  }
  #videoDiv {
    width: 100%;
    height: 420px;
    position: relative;
    overflow: hidden;
  }
  #videoDiv:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:0;
    background:linear-gradient(to right,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4));
  }
  #video1 {
    margin: auto; display: block; width: 100%; height: auto;
    @media (max-width: 768px) {
      height: 50vh;
      object-fit: fill;
    }
  }
  #videoMessage {
    position: absolute;
    top: 0;
    left: 0;
    display:flex;
    flex-direction:column;
    justify-content:v-bind(contentDirection);
    align-items:v-bind(contentDirection);
    place-items:v-bind(contentDirection);
    width: 100%;
    height: fit-content();
    font-weight: bold;
    margin-top: 2rem;
    z-index: 1;
    padding-left: 2rem;
    h1 {
      font-size: 3.5rem;
    }
    h4 {
      font-size: 2rem;
    }
  }
</style>
