<template>
  <div class="home">
    <template v-for="(item, index) in components">
      <SimpleHTML v-if="item.component_id==1" :html="item.content"></SimpleHTML>
      <!--video="https://cdn.systemico.net/wabot/customers/cdalaisla/fondoVideo2.mp4"-->
      <BannerProductComponent v-if="item.component_id==2"
          :title="item.json_config.title"
          :subtitle="item.json_config.subtitle"
          :contentAlign="item.json_config.contentAlign"
          :background="item.json_config.background"
          :buttons="item.json_config.buttons"
          :video="item.json_config.video"
          :width-title="item.json_config.widthTitle"
          :width-subtitle="item.json_config.widthSubtitle"
          :contentDirection="item.json_config.contentDirection"
      />

      <!--<ProductNavComponent />-->
      <GroupPricing v-if="item.component_id==3" :id="item.json_config.id" :items="item.json_config.groupricicing" :title="item.title" :background="item.json_config.background" :title-color="item.json_config.titleColor"></GroupPricing>
      <BannerBenefitsComponent v-if="item.component_id==4" :items="item.json_config.items" :background="item.json_config.background" />
      <ContactAndMap v-if="item.component_id==5" :title="item.title" :subtitle="item.content" :background="item.json_config.background" :textColor="item.json_config.textColor" :withMap="item.json_config.withMap" :lang="lang" />
      <ProductDescriptionComponent v-if="item.component_id==6" :title="item.title" :characteristics="item.json_config.items"></ProductDescriptionComponent>
      <FaqProductComponent v-if="item.component_id==7" :title="item.title" :subtitle="item.content" :faqs="item.json_config.faqs" :background="item.json_config.background" :textColor="item.json_config.textColor" :text-faq-color="item.json_config.textFaqColor" :text-ans-faq-color="item.json_config.textAnsFaqColor" />
      <BannerTipComponent v-if="item.component_id==8"
          :title="item.title"
          :message="item.json_config.message"
          :author="item.json_config.author"
          :icon="item.json_config.icon"
          :icon2="item.json_config.icon2"
          :background="item.json_config.background"
          :textColor="item.json_config.textColor"
          :position="item.json_config.position"
      />
      <PricingPlansComponent v-if="item.component_id==10"
       :plansList='item.json_config.plans'
       currency="COP"
       lang="ES"
      ></PricingPlansComponent >
      <Entry v-if="item.component_id==9" :title="item.title" :author="item.author" :html="item.content"></Entry>
      <BannerStatsComponent v-if="item.component_id==11" :title="item.title" :items="item.json_config.items" :background="item.json_config.background" :text-color="item.json_config.text_color" />
      <CardsProducts v-if="item.component_id==12" :lang="lang" :title="item.json_config.title" :description="item.json_config.description" :subtitle_1="item.json_config.subtitle_1" :subtitle_2="item.json_config.subtitle_2" :subtitle_3="item.json_config.subtitle_3" :products="item.json_config.products" :services="item.json_config.services" :software="item.json_config.software" />
      <CardSections v-if="item.component_id==13" :title="item.title" :json_config="item.json_config" :domain="domain"/>
      <ContentHTML v-if="item.component_id==14" :html="item.content" :title="item.title"></ContentHTML>
      <TestimonialsComponent v-if="item.component_id==15" :title="item.title" :items="item.json_config.items" :background="item.json_config.background" :textColor="item.json_config.textColor" />
      <CardsPeople v-if="item.component_id==16" :title="item.title" :team="item.json_config.team" :background="item.json_config.background" />
      <CardListSections v-if="item.component_id==18" :title="item.title" :json_config="item.json_config" :domain="domain"/>
      <WavesComponent v-if="item.component_id==17" :type="item.json_config.type" />
      <ImagesCarousel v-if="item.component_id==19" :json_config="item.json_config" />
      <Title v-if="item.component_id==20" :title="item.title" :json_config="item.json_config" />
      <Images v-if="item.component_id==21" :json_config="item.json_config" />
      <ListDocuments v-if="item.component_id==22" :json_config="item.json_config" />
    </template>
  </div>
</template>

<script>
import SimpleHTML from "@/packages/ducksites.ui/components/text/SimpleHTML";
import BannerProductComponent from "@/packages/ducksites.ui/components/banners/BannerProductComponent.vue";
import ProductNavComponent from "@/packages/ducksites.ui/components/navs/ProductNavComponent.vue";
import BannerBenefitsComponent from "@/packages/ducksites.ui/components/banners/BannerBenefitsComponent.vue";
import DeckComponent from "@/packages/ducksites.ui/components/cards/DeckComponent";
import ProductDescriptionComponent from "@/packages/ducksites.ui/components/contents/ProductDescriptionComponent";
import PlansComponent from "@/packages/ducksites.ui/components/pricing/PlansComponent";
import GroupPricing from "@/packages/ducksites.ui/components/pricing/GroupPricing.vue";
import PricingPlansComponent from "@/packages/ducksites.ui/components/pricing/PricingPlansComponent.vue";
import FaqProductComponent from "@/packages/ducksites.ui/components/faqs/FaqProductComponent.vue";
import ContactAndMap from "@/packages/ducksites.ui/components/contact/ContactAndMap.vue";
import BannerTipComponent from "@/packages/ducksites.ui/components/banners/BannerTipComponent.vue";
import Entry from "@/packages/ducksites.ui/components/text/entry";
import BannerStatsComponent from "@/packages/ducksites.ui/components/banners/BannerStatsComponent.vue";
import CardsProducts from "@/packages/ducksites.ui/components/cards/CardsProducts.vue";
import CardSections from "@/packages/ducksites.ui/components/cards/CardSections";
import ContentHTML from "@/packages/ducksites.ui/components/text/ContentHTML";
import TestimonialsComponent from "@/packages/ducksites.ui/components/contents/TestimonialsComponent";
import CardsPeople from "@/packages/ducksites.ui/components/cards/CardsPeople";
import CardListSections from "@/packages/ducksites.ui/components/cards/CardListSections";
import WavesComponent from "@/packages/ducksites.ui/components/waves/WavesComponent.vue";
import ImagesCarousel from "@/packages/ducksites.ui/components/contents/ImagesCarousel";
import Title from "@/packages/ducksites.ui/components/text/Title";
import Images from "@/packages/ducksites.ui/components/contents/Images";
import ListDocuments from "@/packages/ducksites.ui/components/contents/ListDocuments";
export default {
  name: 'Section',
  components: {
    ListDocuments,
    Title,
    Images,
    WavesComponent,
    SimpleHTML,
    BannerTipComponent,
    ContactAndMap,
    FaqProductComponent,
    GroupPricing,
    PlansComponent,
    PricingPlansComponent,
    ProductDescriptionComponent,
    BannerBenefitsComponent,
    ProductNavComponent,
    BannerProductComponent,
    DeckComponent,
    Entry,
    BannerStatsComponent,
    CardsProducts,
    CardSections,
    ContentHTML,
    TestimonialsComponent,
    CardsPeople,
    CardListSections,
    ImagesCarousel

  },
  data(){
    return {
      items: [
        { id: 1, title: "Confianza", description: "Servicio experto y de confianza que certifican su vehículo.", icon: "/assets/img/1.svg" },
        { id: 2, title: "Seguridad", description: "Protegemos su vida, las de sus seres queridos y la de la comunidad.", icon: "/static/assets/img/2.svg" },
        { id: 3, title: "Calidad", description: "Aplicamos estandarés de la industria para garantizar un proceso transparente y apropiado.", icon: "/assets/img/3.svg" },
        { id: 4, title: "Tranquilidad", description: "Para que utilice su vehículo conociendo su estado de funcionamiento.", icon: "/assets/img/4.svg" }
      ],
      groupricicing: [
        { id: 1, name: 'Motocicletas', description: 'Revisión tecnico mecanica 2024', image:'https://media.istockphoto.com/id/1455559262/photo/motorbiker-riding-in-austrian-alps.webp?b=1&s=612x612&w=0&k=20&c=UT-rTI43wUO3mniz0IhTZ7FpDnTzdg-PNnTFABKESo0=',
          pricings: [
            {id: 1, concept: 'Modelo 2024-2022', value: '$198.600'},
            {id: 2, concept: 'Modelo 2021-2017', value: '$198.600'},
            {id: 3, concept: 'Modelo 2016-2008', value: '$198.600'},
            {id: 1, concept: 'Modelo 2007 menor', value: '$198.600'}
          ]
        },
        { id: 1, name: 'Livianos particular e hibrido', description: 'Revisión tecnico mecanica 2024', image:'https://media.istockphoto.com/id/1493574434/photo/car-rushes-along-the-highway-at-sunset-low-angle-side-view.webp?b=1&s=612x612&w=0&k=20&c=Lo980gv-8anjo-agAnwjvXf6f_14IbuXxJninetF2xs=',
          pricings: [
            {id: 1, concept: 'Modelo 2024-2022', value: '$198.600'},
            {id: 2, concept: 'Modelo 2021-2017', value: '$198.600'},
            {id: 3, concept: 'Modelo 2016-2008', value: '$198.600'},
            {id: 1, concept: 'Modelo 2007 menor', value: '$198.600'}
          ]
        },
        { id: 1, name: 'Livianos publicos', description: 'Revisión tecnico mecanica 2024', image:'https://media.istockphoto.com/id/1302329554/es/foto/taxis-en-fila-en-alguna-calle-de-bogot%C3%A1-colombia.jpg?s=612x612&w=0&k=20&c=pEHJbJQUGbGTIFiWUHikOWeHzxHNdhZUFkjLhRBT8Jk=',
          pricings: [
            {id: 1, concept: 'Modelo 2024-2022', value: '$198.600'},
            {id: 2, concept: 'Modelo 2021-2017', value: '$198.600'},
            {id: 3, concept: 'Modelo 2016-2008', value: '$198.600'},
            {id: 1, concept: 'Modelo 2007 menor', value: '$198.600'}
          ]
        }
      ],
      domain: '',
      section1: '',
      section2: '',
      components: [],
      faqs: [],
      lang: ''
    }
  },
  beforeMount() {
    let hostname = window.location.hostname;
    let paths = window.location.pathname
    console.log("Domain "+hostname)
    console.log("Path "+paths)
    this.domain = hostname
    let sections = paths.split('/')
    console.log("Secciones encontradas ")
    console.log(sections)
    if(sections.length>=2){
      this.section1 = sections[1]
      if(this.section1==''){
        let ancla = window.location.href
        console.log("Ancla "+ancla)
        if(ancla.indexOf('/#')>-1){
          setTimeout(()=>{
            document.location.href = ancla
          }, 3000)
        }
      }
    }
    if(sections.length>=3){
      this.section2 = sections[2]
      if(this.section2==''){
        let ancla = window.location.href
        console.log("Ancla "+ancla)
        if(ancla.indexOf('/#')>-1){
          setTimeout(()=>{
            document.location.href = ancla
          }, 3000)
        }
      }
    }
    this.load()
  },
  methods:{
    load: function () {
      /*if(this.section1=='tarifas'){
        this.components = []
        let item = {
          id: 1,
          component_id: 2,
          json_config:[

          ],
          title: '',

        }
      }*/
      this.$store.commit('setLoading', true)
      let datos = {}
      if(this.domain && this.domain!=''){
        datos.domain = this.domain
      }
      if(this.section1 && this.section1!=''){
        datos.section1 = this.section1
      }
      if(this.section2 && this.section2!=''){
        datos.section2 = this.section2
      }
      this.$store.dispatch('fetchCompany', datos).then(response => {
        if (response.CODE === 1) {
          console.log('FETCHCOMPANY')
          this.company = response.DATA
          this.lang = this.company.lang
        }
      })
      this.$store.dispatch('fetchComponents', datos).then(response => {
        if(response.CODE==1){
          this.components = response.DATA
          console.log("Components encontrados")
          console.log(this.components)
          for(let i=0; i<this.components.length; i++){
            let item = this.components[i]
            if(item.json_config && item.json_config!=null && item.json_config!=''){
              item.json_config = JSON.parse(item.json_config)
            }
          }

        }
        this.$store.commit('setLoading', false)
      })
    }
  }
}
</script>
