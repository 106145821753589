<template>
  <div style="position: absolute;width: 100%">
    <svg v-if="type===1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00572a" fill-opacity="1" d="M0,224L80,208C160,192,320,160,480,160C640,160,800,192,960,213.3C1120,235,1280,245,1360,250.7L1440,256L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
    <svg v-if="type===2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3f4f5" fill-opacity="1" d="M0,96L288,192L576,288L864,32L1152,256L1440,0L1440,0L1152,0L864,0L576,0L288,0L0,0Z"></path></svg>
  </div>
</template>

<script>
export default {
  name: 'WavesComponent',
  props: {
    color: {
      type: String,
      default: '#00572a'
    },
    type: {
      type: Number,
      default: 1
    }
  }
}
</script>