<template>
  <main id="app" :style="{ '--font-family': font_family }">
      <HeaderComponent :icon="company.icon"
                       :name="company.name"
                       :links="sections"
                       :background="company.json_header.background"
                       :color="company.json_header.color"
                       :show-name="company.json_header.show_name"
                       :height-logo="company.json_header.heightLogo"
                       :type-nav="company.json_header.typeNav"
                       :buttons="company.json_header.buttons"
      />
    <CookieConsent :lang="lang"/>
      <b-container fluid class="w-100 p-0" style="overflow: hidden">
        <router-view />
      </b-container>
      <FooterComponent :name="company.name" :url="hostname"
                       :slogan="company.slogan"
                       :links="links"
                       :logo="company.logo"
                       :logo2="(company.json_footer?company.json_footer.logo2:null)"
                       :info="info"
                       :background="company.json_footer.background"
                       :area-text-color="company.json_footer.areaTextColor"
                        :section-text-color="company.json_footer.sectionTextColor"
                       :text-color="company.json_footer.textColor"
                       :class-footer="company.json_footer.classFooter"
                       :social-networks="company.json_footer.socialNetworks"
                       :cols="company.json_footer.cols"
      />
    <loading-custom></loading-custom>
  </main>
</template>

<script>
import HeaderComponent from './packages/ducksites.ui/components/headers/HeaderComponent.vue'
import FooterComponent from '@/packages/ducksites.ui/components/footers/FooterComponent.vue'
import loadingCustom from '@/packages/ducksites.ui/components/loadingCustom'
import CookieConsent from "@/packages/ducksites.ui/components/headers/CookieConsent";
export default {
  name: 'App',
  metaInfo () {
    return {
      title: this.company.name,
      titleTemplate: '%s: ' + this.company.slogan,
      htmlAttrs: {
        lang: this.company.lang
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.content },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'og:title', content: this.title },
        { name: 'og:image', content: this.image },
        { name: 'og:description', content: this.description },
        { name: 'og:url', content: this.url }
      ]
    }
  },
  data: function () {
    return {
      company: {slogan: ''},
      sections: [],
      links: [],
      font_family: '"Quicksand", sans-serif',
      info: [],
      logo: '',
      logo2: null,
      hostname: '',
      lang: '',
      translation:{
        "ES": {
          "address": "Dirección",
          "phone": "Télefono(s)",
          "email": "Correo electrónico"
        },
        "EN": {
          "address": "Address",
          "phone": "Phone",
          "email": "Email"
        },
        "es_EN": {
          "address": "Address",
          "phone": "Phone",
          "email": "Email"
        }
      }
    }
  },
  components: {
    CookieConsent,
    FooterComponent,
    HeaderComponent,
    loadingCustom
  },
  beforeMount () {
    this.$store.commit('setLoading', true)
    // GET THE DOMAIN
    this.hostname = window.location.hostname
    // GET THE FULL INFORMATION
    const data = {
      domain: this.hostname
    }

    this.$store.dispatch('fetchCompany', data).then(response => {
      if (response.CODE === 1) {
        console.log('FETCHCOMPANY')
        this.company = response.DATA
        this.company.json_header = JSON.parse(this.company.json_header)
        if(this.company.json_footer==null){
          this.company.json_footer = {}
        }else{
          this.company.json_footer = JSON.parse(this.company.json_footer)
        }
        this.lang = this.company.lang
        this.font_family = this.company.font_family
        //this.company.json_footer = JSON.parse(this.company.json_footer)
        // this.metaInfo.title = this.company.name
        // console.log("TITLE: "+this.metaInfo.title)
        // CREATE THE CONTACT JSON
        /*this.info.push({ name: 'Address', value: this.company.address })
        this.info.push({ name: 'Phone', value: this.company.phone })
        this.info.push({ name: 'Email', value: this.company.email })*/
        this.info.push({ name: this.translation[this.lang].address, value: this.company.address })
        this.info.push({ name: this.translation[this.lang].phone, value: this.company.phone })
        this.info.push({ name: this.translation[this.lang].email, value: this.company.email })
        this.lang = window.navigator.language
        this.lang = this.lang.toUpperCase().split('-')[0]

        console.log(this.info)
      }
    })
    // GET THE SECTION INFORMATION HEADER
    let datos = {
      domain: this.hostname,
      type: 1
    }
    this.$store.dispatch('fetchSections', datos).then(response => {
      if (response.CODE === 1) {
        this.sections = response.DATA
      }
    })
    // GET THE SUBSECTION INFORMATION FOOTER
    datos = {
      domain: this.hostname,
      type: 2
    }
    this.$store.dispatch('fetchSections', datos).then(response => {
      if (response.CODE === 1) {
        this.links = response.DATA
      }
    })
  },
  methods: {
    // NOTHING FOR NOW
  }
}
</script>

<!-- GENERAL STYLE FOR ALL APPLICATION -->
<style lang="scss">
@import "./packages/ducksites.ui/variables.scss";
@import "./packages/ducksites.ui/assets/styles/basic.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&display=swap');
html{
  font-size:16px;
}
*{
  font-family: var(--font-family);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
}

a{
  color: #2c3e50;
}
a:active{
  color: #2c3e50;
}
</style>
