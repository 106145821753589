<template>
<div class="row">
  <div v-for="(image, index) in $props.json_config.images" :key="'div_img_'+index" :class="'col-sm-12 col-md-'+image.cols">
    <img :src="image.url" :width="image.width" :height="image.height" :alt="image.alt">
  </div>
</div>
</template>

<script>
export default {
  name: "Images",
  props:{
    json_config: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
